<template>
  <div class="mt-4 mt-lg-0">
    <div v-if="showBound">
      <h4 style="color: Red;">{{ withdrawBound.title }}</h4>
      <div class="form">
        <div class="item1">
          <!--            <div class="item-label">Upgrade to VIP</div>-->
          <div class="item1">Required for VIP upgrade</div>
          <div class="item-control">
            <!--              <input type="number" placeholder="Please enter amount " v-model="amount">-->
            <span style="color:red;">$ {{ withdrawBound.toVipAmount }}</span>
          </div>
        </div>
        <div class="item1" style="margin-top: 1.25rem">
          <div class="item1">Total deposit</div>
          <div class="item-control">
            <span style="color:green;">$ {{ withdrawBound.depositAmount }}</span>
          </div>
        </div>
        <div class="item1" style="margin-top: 1.25rem">
          <div class="item1">Required for deposit</div>
          <div class="item-control">
            <span style="color:red;">$ {{ withdrawBound.remainingAmount }}</span>
          </div>
        </div>
        <div class="item1" style="margin-top: 1.25rem" v-if="withdrawBound.remark!=='' && withdrawBound.remark!==null">
          <div class="item1">Notes</div>
          <div class="item-control">
            <span style="color:black;">{{ withdrawBound.remark }}</span>
          </div>
        </div>
      </div>
    </div>


    <section class="explore-section pt-4">
      <div class="container">
        <!-- filter -->
        <div class="filter-box pb-5">
          <h2 class="mb-4">Filter by</h2>
          <div class="filter-btn-group">
            <a href="javascript:void(0)" class="btn btn-sm filter-btn" :class="{ 'active': selectType === tab.value }"
               v-for="tab in filterMenu" @click.prevent="selectTypeChange(tab.value)" :key="tab.id">{{ tab.title }}</a>
          </div>
        </div><!-- end filter-box -->
      </div><!-- .container -->
    </section><!-- end explore-section -->


    <!--    <div class="user-panel-title-box">-->
    <!--      <h3 class="col-12 col-sm-6">-->
    <!--        <select class="form-select" aria-label="Default select example" v-model="selectType" @change="selectTypeChange">-->
    <!--&lt;!&ndash;          <option selected value="">Open this select type</option>&ndash;&gt;-->

    <!--          <option value="">All</option>-->
    <!--          <option value="60">Withdrawal</option>-->
    <!--          <option value="50">Deposit</option>-->
    <!--          <option value="40">Capital</option>-->

    <!--          <option value="30">Buy</option>-->
    <!--          <option value="20">Affiliates</option>-->
    <!--          <option value="10">Reward</option>-->
    <!--          <option value="0">SOHASH</option>-->
    <!--        </select>-->
    <!--      </h3>-->
    <!--    </div>&lt;!&ndash; end user-panel-title-box &ndash;&gt;-->
    <div class="profile-setting-panel-wrap" v-loading="loading">
      <div class="table-responsive">
        <table class="table mb-0 table-s2">
          <thead class="fs-14">
          <!--          <th scope="col" class="text-center">Date</th>-->
          <!--          <th scope="col" class="text-center">Currency</th>-->
          <!--          <th scope="col" class="text-center">Status</th>-->
          <!--          <th scope="col" class="text-center">Amount</th>-->
          <!--          <th scope="col" class="text-center">USDT</th>-->
          <!--          <th scope="col" class="text-center">Balance</th>-->
          <!--          <th scope="col" class="text-center">Type</th>-->
          <!--          <th scope="col" class="text-center">Wallet Address</th>-->
          <tr>
            <th scope="col" class="text-center" v-for="(list, i) in tableHead" :key="i">{{ list }}</th>
          </tr>
          </thead>
          <tbody class="table-fs">
          <tr v-for="it in records" :key="it.id">
            <!--            <th scope="row"><a href="#">{{ it.id }}</a></th>-->
            <td class="text-center small">{{ getTime(it.updatedAt) }}</td>
<!--            <td class="text-center">-->
<!--              <a class="forum-title fw-bold font-weight-bold text-uppercase" style="color: rgba(12, 102, 255, 1);">-->
<!--                {{ it.coinType }}</a>-->
<!--            </td>-->
            <td class="text-center small">
              <span class="badge fw-medium text-bg-primary" v-if="it.status == 0"> Pending </span>
              <span class="badge fw-medium text-bg-success" v-else-if="it.status == 1"> Paid </span>
              <span class="badge fw-medium text-bg-danger" v-else-if="it.status == -1"> Cancel </span>
              <span class="badge fw-medium text-bg-warning" v-else>
                Invalid
                <!--                v-b-popover.hover.bottomleft="it.remark"-->
                <i class="el-icon-question"></i>
              </span>
            </td>
            <td class="text-center small">{{ it.amount }}&nbsp;<span class="forum-title fw-bold font-weight-bold text-uppercase" style="color: rgba(12, 102, 255, 1);">{{ it.coinType }}</span></td>
            <td class="text-center small">${{(it.amount * it.usdtExchangeRate).toFixed(2)}}
            </td>
            <td class="text-center small">${{ it.balance }}</td>
            <td class="text-center small">
              <span class="badge  text-bg-success" v-if="it.type === 50"> Deposit </span>
              <span class="badge  text-bg-danger" v-else-if="it.type === 60"> Withdrawal </span>
              <span class="badge  text-bg-primary" v-else-if="it.type === 40"> Capital </span>
              <span class="badge  text-bg-info" v-else-if="it.type === 30"> Buy </span>
              <span class="badge  text-bg-success" v-else-if="it.type === 20"> Affiliates </span>
              <span class="badge  text-bg-success" v-else-if="it.type === 10"> Reward </span>
              <span class="badge  text-bg-primary" v-else-if="it.type === 1"> Trial Funds</span>
              <span class="badge text-bg-primary" v-else-if="it.type === 0"> System </span>
            </td>
            <td class="text-center small">{{ maskedAddress(it.address) }}</td>
          </tr>
          </tbody>
        </table>
      </div><!-- end table-responsive -->
      <!-- pagination -->
      <div class="text-center mt-4 mt-md-5">
        <Pagination :records="totalCount" v-model="transForm.pageNum" :per-page="transForm.pageSize"></Pagination>
      </div>
    </div><!-- end profile-setting-panel-wrap-->
  </div><!-- end col-lg-8 -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
import Pagination from 'v-pagination-3';
import date from "@/date";
import storage from "@/storage";
import transApi from "@/apis/transApi";
import walletApi from "@/apis/walletApi";
import Products from "@/components/section/Products.vue";

export default {
  name: 'MyBills',
  components: {
    Products,
    Pagination
  },
  data() {
    return {
      selectedTab: 'all',
      showBound: false,
      withdrawBound: {
        isShow: 0,
        title: '',
        toVipAmount: 0,
        depositAmount: 0,
        remainingAmount: 0,
        remark: ''
      },
      selectType: '',
      SectionData,
      page: 1,
      perPage: 6,
      tableHead: [
        // '#','Date','Currency','Status','Amount','USDT','Balance','Type', 'Wallet Address'
        'Date', 'Status', 'Amount', 'USD', 'Balance', 'Type', 'Wallet Address'
      ],
      loading: false,
      records: [],
      trans: undefined,
      transLoad: 0,
      login: false,
      transForm: {
        status: undefined,
        type: undefined,
        pageNum: 1,
        pageSize: 10
      },
      totalCount: 0,
      totalPages: 1,


      filterMenu: [
        {
          id: 1,
          title: 'All',
          value: ''
        },
        {
          id: 2,
          title: 'Withdrawal',
          value: '60'
        },
        {
          id: 3,
          title: 'Deposit',
          value: '50'
        },
        {
          id: 4,
          title: 'Capital',
          value: '40'
        },
        {
          id: 5,
          title: 'Buy',
          value: '30'
        },
        {
          id: 6,
          title: 'Affiliates',
          value: '20'
        },
        {
          id: 7,
          title: 'Reward',
          value: '10'
        },
        {
          id: 8,
          title: 'System',
          value: '0'
        },
        {
          id: 9,
          title: 'Trial Funds',
          value: '1'
        },
      ]
    }
  },
  computed: {
    // displayedRecords() {
    //   const startIndex = this.perPage * (this.page - 1);
    //   const endIndex = startIndex + this.perPage;
    //   return this.records.slice(startIndex, endIndex);
    // }
  },
  watch: {
    'transForm.pageNum': function () {
      this.getTable()
    }
  },
  created() {
    walletApi.preWithdrawCheck(res => {
      if (res.data !== 'pass') {
        let extraJson = res.data.extraJson
        if (extraJson !== null && extraJson !== '') {
          let innerBound = {
            isShow: extraJson.isShow || 0,
            title: extraJson.title || '',
            toVipAmount: extraJson.toVipAmount || 0,
            depositAmount: extraJson.depositAmount || 0,
            remainingAmount: extraJson.remainingAmount || 0,
            remark: extraJson.remark || ''
          }
          try {
            const parsedJson = JSON.parse(extraJson)
            innerBound = {...innerBound, ...parsedJson}
            if (innerBound.isShow === 1) {
              this.showBound = true
            } else {
              this.showBound = false
            }
          } catch (error) {
            console.error('Invalid JSON string:', error)
          }
          this.withdrawBound = innerBound
        }
      }
    })

    let userId = storage.localGet("userId")
    console.log(userId)
    if (userId) {
      this.login = true;
      this.getTable()
    }

  },
  mounted() {

  },
  methods: {
    getTable() {
      this.loading = true;
      transApi.list(this.transForm, res => {
        this.loading = false;
        console.log(res)
        this.records = res.data?.result || [];
        this.totalCount = res.data?.totalCount || 0;
        this.totalPages = res.data?.totalPages || 1;
        //获取到的数据小于等于全数据时
        // if (this.trans.result.length <= this.transForm.pageSize) this.transLoad = -1
      })
    },
    getDate(data) {
      return date.getDate(data)
    },
    getTime(data) {
      return date.getTime(data)
    },
    selectTypeChange(e) {
      console.log(e)
      this.selectType = e
      this.transForm.type = this.selectType;
      this.search()
    },
    search() {
      this.transForm.pageNum = 1;
      this.totalCount = 0;
      this.totalPages = 0;
      this.records = [];
      this.getTable()
    },
    maskedAddress(addr) {
      if(addr){
        const length = addr.length
        const keepLength = Math.min(Math.floor(length / 4), 5)
        const prefix = addr.slice(0, keepLength)
        const suffix = addr.slice(-keepLength)
        return `${prefix}****${suffix}`
      } else {
        return ''
      }
    }

  }
}
</script>


<style scoped lang="scss">

.filter-btn.active {
  background-color: darksalmon;
}

.table-fs {
  font-size: 0.975rem;
}


.form {
  //width: 600px;
  //height: 370px;
  background: #F8F9FC;
  border-radius: 0.5rem;
  padding: 1.3125rem;
  margin-bottom: 8px;

  .item1 {
    &-label {
      height: 1.3125rem;
      font-size: .9375rem;
      //font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333947;
      line-height: 1.3125rem;
    }

    &-control {
      margin-top: 0.625rem;

      input {
        width: 100%;
        height: 2.6875rem;
        border: 1px solid #FFFFFF;
        padding: 0 1rem;
        background: #FFFFFF;
        border-radius: 4px;
        //height: 1.3125rem;
        font-size: .9375rem;
        //font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1C2A46;
        line-height: 2.6875rem;
        //caret-color:  rgba(var(--bs-link-color-rgb));
        transition: all .3s ease;

        &:focus {
          outline: none;
          border: 1px solid rgba(var(--bs-link-color-rgb));
        }

        &::-webkit-input-placeholder {
          /* WebKit browsers，webkit内核浏览器 */
          color: #8091A7;
        }

        &:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #8091A7;

        }

        &::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #8091A7;

        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: #8091A7;

        }


      }


      .select {
        //margin: 0;
        &-i {
          background: #FFFFFF;
          border-radius: 4px;
          padding: 1.25rem 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border: 1px solid transparent;
          transition: all 0.3s ease;

          &.active {
            border-color: rgba(var(--bs-link-color-rgb));
          }

          span {
            margin-top: 0.625rem;
            font-size: 0.9375rem;
            //font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1C2A46;
            line-height: 1.3125rem;
          }
        }
      }
    }
  }

}

</style>
import Axios from "../axios";
import { outErr, toForm } from "./apiConfig";
const url = "/api/transaction"



export default {
    //查看列表
    list({
        status,
        type,
        pageNum,
        pageSize
    }, out) {
        Axios.get(url + "/list", {
            status,
            type,
            pageNum,
            pageSize
        }).then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },
    //重新提交审核
    reSubmission(transactionId, out) {
        Axios.put(url + "/reSubmission", toForm({ transactionId })).then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    }
}